#print-content-root {
  display: none;
}

@media print {
  body.print-content-mode {
    background-color: transparent;
  }

  body.print-content-mode > *:not(#print-content-root) {
    display: none;
  }

  #print-content-root {
    display: block;
  }
}